@charset "utf-8"
@import '../../../node_modules/bulma/sass/utilities/_all.sass'
@import '../../../node_modules/bulma/sass/base/_all.sass'
@import '../../../node_modules/bulma/sass/elements/button.sass'
@import '../../../node_modules/bulma/sass/elements/container.sass'
@import '../../../node_modules/bulma/sass/components/navbar.sass'
@import '../../../node_modules/bulma/sass/components/modal.sass'
@import '../../../node_modules/bulma/sass/grid/columns.sass'
@import '../../../node_modules/bulma/sass/layout/hero.sass'
@import '../../../node_modules/bulma/sass/helpers/_all.sass'
@import '../../../node_modules/bulma/sass/elements/box.sass'
@import '../../../node_modules/bulma/sass/form/_all.sass'
@import '../../../node_modules/bulma/sass/form/checkbox-radio.sass'
@import '../../../node_modules/bulma/sass/components/card.sass'
@import '../../../node_modules/bulma/bulma.sass'
@import '../../../node_modules/bulma-switch/src/sass/index.sass'
$tooltip-arrow-size: 0px

@import "@creativebulma/bulma-tooltip"
@import "~@creativebulma/bulma-divider"

.fix-footer 
  position: absolute
  width: 100%
  bottom: 0
  overflow:hidden

ul 
  list-style-type: disc !important

.columns.is-vcentered
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center

.is-full
  height: 100vh

.has-margin-top-auto
  margin-top: auto  

html
  height: 100vh
  width: 100vw

body
  margin: 0
  height: 100%
  width: 100%
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

.is-disabled
  pointer-events: none
  opacity: 0.7

.fullscreenmodal
  padding: 0 !important

  .modal-dialog
    width: 1024px
    max-width: 80vw
    height: 100%
    margin: 0

  .modal-content
    width: 1024px
    max-width: 80vw
    height: 100%
    border: 0
    border-radius: 0

  .modal-body
    overflow-y: auto

.element
  .element-tools
    display: none
    cursor: pointer

  &.highlighted .element-tools
    display: inline

.joint-type-uml-composition .marker-target
  fill: #4a4e69
  stroke: #4a4e69

.full-overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 100
  